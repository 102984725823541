import React, { useState } from "react";

import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { AnyProduct, ProductBundle, ProductDigital, ProductPrint, ProductPrintIssue, Publication } from "@api/graphql/types";
import { NormalProduct, productIsDigital, publicationIsDigital } from "@api/graphql/typeHelpers";
import { Badge } from "@components/Badge";
import { Button, QuantityButton } from "@components/Button";
import { Card } from "@components/Card/Card";
import { OriginalPrice, Price, ProductImage } from "@components/Card/Elements";
import { DigitalImageFrame } from "@components/DigitalImageFrame";
import { Flex } from "@components/Flex";
import { Typography } from "@components/Typography";
import { useCart } from "@hooks/useCart";
import { ApplicationState } from "@redux/reducers";
import { bp } from "@styles/theme";
import { ImageUtils } from "@utils/ImageUtils";
import { getProductPageLinkParams, Path } from "@utils/Path";
import { StringUtils } from "@utils/StringUtils";
import { useLoctool } from "@bigfish/react-loctool";
import { CURRENCY_FORMAT_OPTIONS } from "@utils/Constants";
import Modal from "@components/Modal";
import { HtmlUtils } from "@utils/HtmlUtils";

interface ProductCardProps {
    product: AnyProduct;
    className?: string;
    buttonLabel?: string;
    boughtByOthers?: boolean;
    hideType?: boolean;
    onCartQuantityChange?: (product: NormalProduct, oldQuantity: number, currentQuantity: number) => void;
    onClick?: () => void;
}

interface PublicationCardProps {
    publication: Publication;
    forceDigitalFrame?: boolean;
    buttonLabel?: string;
}

export const NormalCard = (props: Omit<ProductCardProps, "product"> & { product: ProductPrint | ProductDigital | ProductPrintIssue | ProductBundle }) => {
    const Intl = useLoctool();
    const { product, className, buttonLabel, boughtByOthers, onCartQuantityChange } = props;
    const router = useRouter();
    const [imageModalActive, setImageModalActive] = useState(false);
    const items = useSelector((state: ApplicationState) => state.cart.allItems);
    const isItemInCart = items.some(item => item.product.id === product.id);
    const itemQuantity = items.filter(x => x.product.id === product.id).length;
    const { addProduct, removeLastAddedProduct, loading } = useCart();

    const refreshCart = () => {
        router.replace(router.asPath, undefined, { scroll: false });
    };

    const handleAdd = async () => {
        const newQuantity = await addProduct(product.id);
        onCartQuantityChange?.(product, itemQuantity, newQuantity);
        refreshCart();
    };

    const handleRemove = async () => {
        const newQuantity = await removeLastAddedProduct(product.id);
        onCartQuantityChange?.(product, itemQuantity, newQuantity);
        refreshCart();
    };

    const discountPercent = StringUtils.discountPercent(
        "standard_price" in product ? product.standard_price ?? 0 : 0,
        product.discount_price,
        product.price.unit_price,
    );

    const hasStandardPrice =
        "standard_price" in product &&
        product.standard_price &&
        product.standard_price !== 0 &&
        product.standard_price > (product.discount_price ?? product.price.unit_price);

    const renderContent = (onImageClick?: () => void) => (
        <StyledProductCard
            className={`${className} _ce_measure_widget _ce_measure_link`}
            data-ce-measure-widget={`termek-${HtmlUtils.removeTags(product.name)}`}
            $boughtByOthers={boughtByOthers}
            onClick={props.onClick ?? onImageClick}
        >
            {product.text_featured && <ProductCardUpTitle>{product.text_featured}</ProductCardUpTitle>}
            <ImageWrapper>
                {productIsDigital(product) ? (
                    <DigitalImageFrame image={ImageUtils.productImageWithFallback(product, { width: 200 })} alternate={HtmlUtils.removeTags(product.name)} />
                ) : (
                    <ProductImage image={ImageUtils.productImageWithFallback(product, { width: 200 })} alternate={HtmlUtils.removeTags(product.name)} />
                )}
                {product.is_badge_discount && discountPercent > 0 && <ProductCardBadge text={`-${discountPercent}%`} color="red" />}
            </ImageWrapper>
            <ProductCardTitle>
                <div dangerouslySetInnerHTML={{ __html: HtmlUtils.sanitize(product.name) }} />
            </ProductCardTitle>
            {!props.hideType && <ProductCardType>{product.type_info}</ProductCardType>}
            <ProductCardPriceContainer>
                {"standard_price" in product && product.standard_price && hasStandardPrice && (
                    <ProductCardOriginalPrice>{Intl.formatNumber(product.standard_price, CURRENCY_FORMAT_OPTIONS)}</ProductCardOriginalPrice>
                )}
                <ProductCardPrice>{Intl.formatNumber(product.discount_price ?? product.price.unit_price, CURRENCY_FORMAT_OPTIONS)}</ProductCardPrice>
            </ProductCardPriceContainer>
            {!isItemInCart && (
                <ProductCardButton
                    $marginTopAuto={props.hideType}
                    disabled={loading}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        handleAdd();
                    }}
                >
                    {buttonLabel ? buttonLabel : `${Intl.formatMessage({ id: "pages.home.wideProductCard.addToCart" })}`}
                </ProductCardButton>
            )}
            {isItemInCart && itemQuantity && (
                <ProductCardQuantity $disabled={loading}>
                    <QuantityButton name="productQuantity" max={10} quantity={itemQuantity} onAdd={handleAdd} onRemove={handleRemove} />
                </ProductCardQuantity>
            )}
        </StyledProductCard>
    );

    return product.is_public ? (
        <Link href={getProductPageLinkParams(product)} passHref>
            {renderContent()}
        </Link>
    ) : (
        <>
            {renderContent(() => setImageModalActive(true))}
            <ImageModal
                titleText={HtmlUtils.removeTags(product.name)}
                mounted={imageModalActive}
                underlayClickable={true}
                onModalClose={() => setImageModalActive(false)}
                className="_ce_measure_widget"
                data-ce-measure-widget="popup-image-modal"
            >
                <img {...ImageUtils.productImageWithFallback(product, { width: 500 })} alt={HtmlUtils.removeTags(product.name)} />
            </ImageModal>
        </>
    );
};

export const ProductCard = (props: ProductCardProps) => {
    const { product, ...otherProps } = props;
    switch (product.__typename) {
        case "ProductPrint":
        case "ProductDigital":
        case "ProductPrintIssue":
        case "ProductBundle": {
            return <NormalCard product={product as ProductPrint | ProductDigital | ProductPrintIssue | ProductBundle} {...otherProps} />;
        }
        default: {
            console.error(`Unknown type ${product.__typename}`);
            return null;
        }
    }
};

export const PublicationCard = (props: PublicationCardProps) => {
    const Intl = useLoctool();
    const { publication, buttonLabel } = props;
    return (
        <Link
            href={{
                pathname: `${Path.product}/${publication.short_name}`,
                query: publicationIsDigital(publication) || props.forceDigitalFrame ? { id: publication.id, digital: "true" } : { id: publication.id },
            }}
        >
            <StyledProductCard className="_ce_measure_widget _ce_measure_link" data-ce-measure-widget={`termek-${publication.name}`}>
                <ImageWrapper>
                    {publicationIsDigital(publication) || props.forceDigitalFrame ? (
                        <DigitalImageFrame image={ImageUtils.publicationImageWithFallback(publication, { width: 200 })} alternate={publication.name} />
                    ) : (
                        <ProductImage image={ImageUtils.publicationImageWithFallback(publication, { width: 200 })} alternate={publication.name} />
                    )}
                </ImageWrapper>
                <ProductCardTitle>{publication.name}</ProductCardTitle>
                <ProductCardPriceFrom>
                    {Intl.formatMessage(
                        { id: "pages.magazines.productCard.price" },
                        { price: Intl.formatNumber(publication.price?.unit_price ?? 0, CURRENCY_FORMAT_OPTIONS) },
                    )}
                </ProductCardPriceFrom>
                <Link
                    href={{
                        pathname: `${Path.product}/${publication.short_name}`,
                        query: publicationIsDigital(publication) || props.forceDigitalFrame ? { id: publication.id, digital: "true" } : { id: publication.id },
                    }}
                >
                    <ProductCardButton>{buttonLabel ? buttonLabel : `${Intl.formatMessage({ id: "pages.magazines.productCard.button" })}`}</ProductCardButton>
                </Link>
            </StyledProductCard>
        </Link>
    );
};

const ImageModal = styled(Modal)`
    width: auto !important;
    height: fit-content;
    padding: 20px;
    border-radius: 0 !important;
    box-shadow: none !important;

    ${bp.large} {
        padding: 0;
    }

    img {
        width: 100%;
        box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
    }
`;

export const ProductCardUpTitle = styled(Typography.UpTitle)`
    display: -webkit-box;
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 30px;
    font-size: 16px;
    line-height: 35px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (min-width: 375px) {
        font-size: 18px;
        line-height: 35px;
    }

    ${bp.medium} {
        padding: 0 40px;
        font-size: 20px;
        line-height: 40px;
    }

    ${bp.large} {
        padding: 0 50px;
        line-height: 50px;
    }

    ${bp.xlarge} {
        font-size: 23px;
        line-height: 50px;
    }
`;

const ProductCardTitle = styled(Typography.H3)`
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px;

    ${bp.medium} {
        font-size: 17px;
        line-height: 24px;
    }
`;

const ProductCardPriceFrom = styled(Typography.Small)`
    margin-top: auto;
    margin-bottom: 15px;
`;

const ProductCardType = styled(Typography.Small)`
    margin-top: auto;
    margin-bottom: 15px;
`;

const ProductCardPriceContainer = styled(Typography.Small)`
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
`;

const ProductCardButton = styled(Button.Primary)<{ $marginTopAuto?: boolean }>`
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
    margin: 0 auto;

    ${p => p.$marginTopAuto && "margin-top: auto;"}

    ${bp.medium} {
        padding-right: 10px;
        padding-left: 10px;
    }

    ${bp.large} {
        padding-right: 30px;
        padding-left: 30px;
    }
`;

const ProductCardQuantity = styled.div<{ $disabled?: boolean }>`
    width: fit-content;
    margin: 0 auto;

    ${props => props.$disabled === true && "opacity: 0.6; pointer-events: none;"}
`;

const ProductCardOriginalPrice = styled(OriginalPrice)`
    margin-right: 20px;
    font-size: 20px;
`;

const ProductCardPrice = styled(Price)`
    font-size: 20px;
`;

const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 260px;
    padding: 0 15px 0;
    margin: 0 auto;

    ${bp.medium} {
        padding: 0 10px;
    }

    ${bp.large} {
        padding: 0 30px;
    }
`;

const ProductCardBadge = styled(Badge)`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

const StyledProductCard = styled(Card)<{ $boughtByOthers?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 35px 10px 25px 10px;
    margin: 0;
    border: 1px solid ${props => props.theme.color.grayL};
    text-align: center;
    cursor: pointer;

    ${bp.medium} {
        padding: 40px 10px 25px;
    }

    ${bp.large} {
        padding: 50px 20px 50px 20px;
    }

    ${props =>
        props.$boughtByOthers &&
        `
        padding: 20px;

        ${ProductCardUpTitle} {
            line-height: 40px;
        }

        ${ImageWrapper} {
            width: 100%;
            max-width: 150px;
            padding-top: 20px;
            padding-right: 0;
            padding-left: 0;
            margin: 0 auto;
        }

        ${ProductCardBadge} {
            left: -15px;
            bottom: -10px;
        }

        ${ProductCardType} {
            font-size: 12px;
            line-height: 17px;
        }

        @media screen and (min-width: 375px) {
            padding-top: 20px;
        }

        ${bp.medium} {
            padding: 20px 20px 35px;

            ${ImageWrapper} {
                padding-top: 20px;
            }
        }

        ${bp.large} {
            padding-top: 20px;
            padding-bottom: 50px;

            ${ImageWrapper} {
                max-width: 150px;
                padding-top: 25px;
            }

            ${ProductCardBadge} {
                left: -35px;
                bottom: -10px;
            }

            ${ProductCardUpTitle} {
                line-height: 45px;
            }

            ${ProductCardType} {
                font-size: 14px;
                line-height: 20px;
            }
        }

        ${bp.xlarge} {
            padding-top: 25px;

            ${ProductCardUpTitle} {
                line-height: 50px;
            }
        }
    `}
`;

export const ProductCardGrid = styled(Flex.Container).attrs({
    $flexDirection: "column",
    $justifyContent: "center",
})`
    padding-bottom: 20px;
    margin-top: -20px;

    ${StyledProductCard} {
        margin-top: 20px;
    }

    @media screen and (min-width: 375px) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -10px;
        margin-left: -10px;

        ${StyledProductCard} {
            width: calc(50% - 20px);
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    ${bp.medium} {
        ${StyledProductCard} {
            width: calc(25% - 20px);
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    ${bp.xlarge} {
        padding-bottom: 40px;
        margin-top: -40px;
        margin-right: -20px;
        margin-left: -20px;

        ${StyledProductCard} {
            width: calc(25% - 40px);
            margin-top: 40px;
            margin-right: 20px;
            margin-left: 20px;
        }
    }
`;
