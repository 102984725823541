import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import { BasePath } from "@utils/Path";
import { useLoctool } from "@bigfish/react-loctool";
import { HtmlUtils } from "@utils/HtmlUtils";

interface MetaProps {
    title?: string;
    description?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
}

export const Meta: React.FC<MetaProps> = ({ title, description, ogTitle, ogDescription, ogImage }) => {
    const Intl = useLoctool();
    const { asPath } = useRouter();

    return (
        <Head>
            <title>{title ? HtmlUtils.removeTags(title) : Intl.formatMessage({ id: "common.brandName" })}</title>
            <meta
                name="description"
                content={description ? HtmlUtils.removeTags(description) : Intl.formatMessage({ id: "common.brandDescription" })}
                key="description"
            />
            <meta
                property="og:title"
                name="og:title"
                content={ogTitle ? HtmlUtils.removeTags(ogTitle) : Intl.formatMessage({ id: "common.brandName" })}
                key="og:title"
            />
            <meta
                property="og:description"
                name="og:description"
                content={ogDescription ? HtmlUtils.removeTags(ogDescription) : Intl.formatMessage({ id: "common.brandDescription" })}
                key="og:description"
            />
            <meta property="og:url" name="og:url" content={BasePath + asPath} key="og:url" />
            <meta property="og:image" name="og:image" content={ogImage ?? "/images/mc-og-image.jpg"} key="og:image" />
        </Head>
    );
};
