import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { bp } from "@styles/theme";
import { Flex } from "@components/Flex";

interface BadgeProps {
    text: string;
    color: keyof DefaultTheme["color"];
    className?: string;
}

export const Badge = ({ text, color, className }: BadgeProps) => {
    return (
        <StyledBadge $color={color} className={className}>
            <span>{text}</span>
        </StyledBadge>
    );
};

const StyledBadge = styled(Flex.Container).attrs({
    $alignItems: "center",
    $justifyContent: "center",
})<{ $color: keyof DefaultTheme["color"] }>`
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: ${props => props.theme.color.white};
    font-size: 22px;
    font-family: "Caveat", cursive;

    ${props => css`
        background: ${props.theme.color[props.$color]};
    `};

    ${bp.large} {
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 32px;
    }

    span {
        position: relative;
        top: -1px;
        left: -1px;
    }
`;
