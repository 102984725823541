import { Mutations } from "@api/graphql/queries/Mutations";
import {
    cartAddChristmasProducts,
    cartAddChristmasProductsVariables,
    cartAddProduct,
    cartAddProductVariables,
    cartFlush,
    cartFlushVariables,
    cartRemove,
    cartRemoveVariables,
    CartType,
} from "@api/graphql/types";
import { CartActions } from "@redux/actions/CartActions";
import { ApplicationState } from "@redux/reducers";
import { useMutation } from "graphql-hooks";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { batchActions } from "redux-batched-actions";

export const useCart = () => {
    const dispatch = useDispatch();
    const cartReducer = useSelector((state: ApplicationState) => state.cart);
    const [loading, setLoading] = useState(false);
    const [addProductMutation] = useMutation<cartAddProduct, cartAddProductVariables>(Mutations.cartAddProduct);
    const [cartFlushMutation] = useMutation<cartFlush, cartFlushVariables>(Mutations.cartFlush);
    const [removeProductMutation] = useMutation<cartRemove, cartRemoveVariables>(Mutations.cartRemove);
    const [addChristMasProductsMutation] = useMutation<cartAddChristmasProducts, cartAddChristmasProductsVariables>(Mutations.cartAddChristmasProducts);

    const addProduct = async (product_id: number) => {
        setLoading(true);
        const result = await addProductMutation({ variables: { product_id } });
        const cart = result.data?.cartAddProduct;
        let newQuantity = 0;
        if (cart) {
            dispatch(CartActions.updateCart(cart));
            newQuantity = cart.items.filter(i => i.product.id === product_id).length;
        }
        setLoading(false);
        return newQuantity;
    };

    const removeCartItem = async (cart_item_id: string) => {
        setLoading(true);
        const result = await removeProductMutation({ variables: { cart_item_id } });
        const cart = result.data?.cartRemove;
        if (cart) {
            dispatch(CartActions.updateCart(cart));
        }
        setLoading(false);
    };

    const removeLastAddedProduct = async (product_id: number) => {
        const cartItemsFound = cartReducer.allItems.filter(p => p.product.id === product_id);
        if (cartItemsFound.length > 0) {
            const lastItem = cartItemsFound.pop();
            await removeCartItem(lastItem!.id);
            return cartItemsFound.length - 1;
        }
        return 0;
    };

    const cartFlush = async (type: CartType) => {
        setLoading(true);
        const result = await cartFlushMutation({ variables: { type } });
        const carts = result.data?.cartFlush;
        if (carts) {
            dispatch(batchActions(carts.map(CartActions.updateCart)));
        }
        setLoading(false);
    };

    const cartAddChristmasProducts = async (product_ids: number[]) => {
        setLoading(true);
        const result = await addChristMasProductsMutation({ variables: { product_ids } });
        const cart = result.data?.cartAddChristmasProducts;
        if (cart) {
            dispatch(CartActions.updateCart(cart));
        }
        setLoading(false);
    };

    const getCartItemById = (cartItemId: string) => {
        return cartReducer.allItems.find(item => item.id === cartItemId);
    };

    return {
        addProduct,
        removeLastAddedProduct,
        removeCartItem,
        allCartItems: cartReducer.allItems,
        normalCart: cartReducer.normalCart,
        serviceCart: cartReducer.serviceCart,
        loading,
        cartFlush,
        cartAddChristmasProducts,
        getCartItemById,
    };
};
